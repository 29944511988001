<ng-template [ngTemplateOutlet]="onlyIcon ? iconTmpl : contentTmpl" />

<ng-template #iconTmpl>
  <div
    class="eb-form-item eb-form-item--floating"
    [ngClass]="{
      'eb-form-item--only-icon': onlyIcon,
      'eb-form-item--required': required,
      'eb-form-item--radio': radio
    }"
  >
    <div
      class="eb-control"
      [ngClass]="{
        'eb-control--light': theme === 'light',
        'eb-size--lg': size === 'large',
        'eb-size--sm': size === 'small'
      }"
    >
      <div class="eb-control-value">
        <svg-icon *ngIf="prefixIcon" size="lg" [key]="prefixIcon" />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentTmpl>
  <div
    class="eb-form-item eb-form-item--floating"
    [ngClass]="{
      'eb-form-item--with-prefix': prefixIcon,
      'eb-form-item--with-sufix': suffixIcon,
      'eb-form-item--only-icon': onlyIcon,
      'eb-form-item--required': required,
      'eb-form-item--radio': radio,
      'eb-form-item--lg': size === 'large',
      'eb-form-item--sm': size === 'small'
    }"
  >
    <div
      class="eb-control"
      [ngClass]="{
        'eb-control--light': theme === 'light',
        'eb-size--lg': size === 'large',
        'eb-size--sm': size === 'small'
      }"
    >
      <ng-content />

      <div class="eb-form-item__label" [ngClass]="{ 'disabled relative': isDisabled }">
        <label class="ellipsis" [ngClass]="{ disabled: isDisabled }">{{ label }}</label>
      </div>
    </div>
    <svg-icon *ngIf="prefixIcon" class="prefix-icon" size="lg" [key]="prefixIcon" />

    <svg-icon
      *ngIf="suffixIcon && !isClearIcon && !isDisabled"
      class="suffix-icon"
      size="mde"
      [key]="suffixIcon"
      [ngClass]="{ disabled: isDisabled }"
    />
    <svg-icon *ngIf="isClearIcon && !isDisabled" key="close" class="suffix-icon" size="mde" (click)="onClearInput($event)" />
  </div>
</ng-template>
