/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { CookieName, CookiesService } from './cookies.service';
import { isPlatformBrowser } from '@angular/common';
import { GtmInitializerService } from '../../../gtm/src/lib/gtm-initializer.service';

export interface CookieConsent {
    marketing: boolean,
    necessary: boolean,
    preferences: boolean,
    statistics: boolean,
    method?: string,
    stamp?: string,
}

@Injectable({
    providedIn: 'root',
})
export class CookiesPolicyService {
    private readonly _cookiesService = inject(CookiesService);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _cookiesPolicyConsent$ = new ReplaySubject<CookieConsent|undefined>(1);
    private readonly _gtmInitService = inject(GtmInitializerService);

    public get cookiesPolicyConsent$(): Observable<CookieConsent|undefined> {
        return this._cookiesPolicyConsent$.asObservable();
    }

    public shareAcceptCookiesPolicy(value: CookieConsent|undefined): void {
        this._cookiesPolicyConsent$.next(value);
    }

    public verifyCookiesConsentIframe(): void {
        if (!isPlatformBrowser(this._platformId)) {
            return;
        }

        const cookieValue = this._cookiesService.getCookieValue(CookieName.CookieAgree);
        let cookiesConsent: CookieConsent|undefined;
        try { cookiesConsent = JSON.parse(cookieValue) } catch(e) {}

        if (cookiesConsent) {
            this.shareAcceptCookiesPolicy({
                marketing: !!cookiesConsent?.marketing,
                necessary: true,
                preferences: !!cookiesConsent?.preferences,
                statistics: !!cookiesConsent?.statistics
            });
        } else {
            this.shareAcceptCookiesPolicy(undefined);
        }
    }

    public acceptCookiesPolicyIframe(): void {
        this._gtmInitService.manualConsentUpdate();
        this._setCookiesPolicyConsentIframe(true);
    }

    public rejectCookiesPolicyIframe(): void {
        this._setCookiesPolicyConsentIframe(false);
    }

    private _setCookiesPolicyConsentIframe(accept: boolean): void {
        const now = new Date();
        const cookiesConsent = {
            marketing: accept,
            necessary: true,
            preferences: accept,
            statistics: accept
        };

        if (accept) {
            now.setFullYear(now.getFullYear() + 1);
        } else {
            now.setMonth(now.getMonth() + 3);
        }

        this._cookiesService.setCookieValue(
            CookieName.CookieAgree,
            JSON.stringify(cookiesConsent),
            { expires: now, path: '/', domain: true, secure: true, sameSite: 'None', partitioned: true },
        );

        this.shareAcceptCookiesPolicy(cookiesConsent);
    }
}
