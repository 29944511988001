import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
  inject, PLATFORM_ID
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SvgIcons } from '@ngneat/svg-icon';
import { EbInputSize, EbInputTheme } from 'libs/ui-form-item/src/lib/form-item/form-item.component';
import { ISelectOption } from '../select-option/select-option.interface';
import { EbSelectOptionComponent } from './../select-option/select-option.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'eb-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EbSelectComponent), multi: true }],
})
export class EbSelectComponent<T> implements OnChanges, AfterContentInit, ControlValueAccessor {
    private _platformId = inject(PLATFORM_ID);
    private readonly _elementRef = inject(ElementRef);
    public readonly changeDetectorRef = inject(ChangeDetectorRef);

    private _value: ISelectOption<T> | null = null;
    private _destroyRef = inject(DestroyRef);
    protected get value(): ISelectOption<T> | null {
        return this._value;
    }

    isOpen = false;
    searchFormControl = new FormControl();
    origin: CdkOverlayOrigin;
    cdkFixed = false;

    @Input() label = '';
    @Input() icon?: SvgIcons;
    @Input() size: EbInputSize = 'default';
    @Input() theme: EbInputTheme = 'dark';
    @Input() placeholder = 'libs.tree-select.select';
    @Input() loading = false;
    @Input() allowClear = true;
    @Input() serverFilter = false;
    @Input() customValue = false;
    @Input() inline = false;
    @Input() required = false;
    @Input() hintMode = false;
    @Input() overlayOrigin?: ElementRef;
    @Input() overlayWidth: string | number = '';
    @Input() suffixIcon: SvgIcons | undefined = 'chevron-down';
    @Input() showSuffixIcon = true;
    @Input() isContactFormEvent = false;
    @Input() isDisabled = false;

    @Output() readonly searching = new EventEmitter<string>();
    @Output() readonly selectedChanged = new EventEmitter<ISelectOption<T> | null>();
    @Output() readonly clearInput = new EventEmitter<void>();

    @HostBinding('class.eb-select') protected get isPicker(): boolean {
        return true;
    }

    @HostBinding('class.eb-select--borderless') @Input() borderless = false;

    @ContentChildren(EbSelectOptionComponent) private readonly _options!: QueryList<EbSelectOptionComponent<T>>;
    @ViewChild('inputElement') readonly inputElement!: ElementRef<HTMLInputElement>;
    @ViewChild(CdkConnectedOverlay) readonly cdkConnectedOverlay: CdkConnectedOverlay | undefined;

    @HostListener('click') protected onClickInputBox(): void {
        this.focus();
    }

    private _onTouched = () => {};
    private _onChange: (value: ISelectOption<T> | null) => void = () => {};

    constructor() {
        this.origin = new CdkOverlayOrigin(this._elementRef);
        this.searchFormControl.valueChanges.pipe(takeUntilDestroyed()).subscribe((searchPhrase) => {
            // TODO: PE-3279 zrobić to lepiej - hotfix na szybko https://ebiletpl.atlassian.net/browse/MNT-2015
            if (!this.cdkFixed && this.cdkConnectedOverlay) {
                this.cdkConnectedOverlay.overlayRef.updatePosition();
                this.cdkFixed = true;
            }

            if (this.hintMode) {
                this.select({
                    value: searchPhrase,
                    label: searchPhrase,
                });
            }
            this.searching.next(searchPhrase);
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.overlayOrigin?.currentValue) {
            this.origin = new CdkOverlayOrigin(changes.overlayOrigin.currentValue);
        } else {
            this.origin = new CdkOverlayOrigin(this._elementRef);
        }
    }

    /* eslint-disable @typescript-eslint/no-empty-function */
    public ngAfterContentInit(): void {}

    public writeValue(obj: ISelectOption<T>): void {
        if (this.hintMode && !obj) {
            this.searchFormControl.setValue(null, { emitEvent: false });
        }

        this._value = obj;
        this.changeDetectorRef.detectChanges();
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public focus(): void {
        this.inputElement?.nativeElement.focus();
        this.open();
    }

    protected onOverlayOutsideClick(event: MouseEvent): void {
        if (!(this._elementRef.nativeElement as any).contains(event.target)) {
            this.close();
        }
    }

    public open(): void {
        if (!this.isOpen) {
            this.isOpen = true;
            this.changeDetectorRef.markForCheck();
            this.changeDetectorRef.detectChanges();

            // TODO: PE-3279 zrobić to lepiej - hotfix na szybko https://ebiletpl.atlassian.net/browse/MNT-2015
            if (isPlatformBrowser(this._platformId)) {
                setTimeout(() => {
                    this.cdkConnectedOverlay?.overlayRef.updatePosition();
                }, 800);
            } else {
                this.cdkConnectedOverlay?.overlayRef.updatePosition();
            }
        }
    }

    public close(): void {
        if (!this.customValue && !this._value) {
            this.clearSearch();
        }

        this.isOpen = false;
        this.changeDetectorRef.markForCheck();
    }

    public invokeClick(): void {
        this._elementRef.nativeElement.click();
    }

    public select(item: ISelectOption<T>): void {
        if (this.hintMode) {
            this.searchFormControl.setValue(item?.label, { emitEvent: false });
        }

        this._value = item;
        this._onChange(this._value);
        this.selectedChanged.next(this._value);

        if (!this.isContactFormEvent) {
            this.close();
        }
        this.changeDetectorRef.markForCheck();
    }

    protected clear(): void {
        this._value = null;
        this._onChange(this._value);
        this.selectedChanged.next(this._value);
        this.clearSearch();
        if (this.clearInput.observed) {
            this.clearInput.emit();
        }
    }

    public clearSearch(): void {
        this.searchFormControl.reset();
    }
}
