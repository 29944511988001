import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    ViewEncapsulation,
    inject,
} from '@angular/core';
import { EbSelectComponent } from '../select/select.component';
import { ISelectOption } from './select-option.interface';

@Component({
    selector: 'eb-select-option',
    templateUrl: './select-option.component.html',
    styleUrls: ['./select-option.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class EbSelectOptionComponent<T> {
    private readonly _ebSelectComponent = inject(EbSelectComponent<T>);

    @Output() readonly selected = new EventEmitter<ISelectOption<T>>();

    @Input({ required: true }) option!: ISelectOption<T>;

    @HostBinding('class.eb-select-option') protected get isOption(): boolean {
        return true;
    }

    @HostBinding('class.eb-select-option--focused') @Input() focused = false;

    @HostListener('click') protected click(): void {
        this._ebSelectComponent.select(this.option);
    }
}
