import { Injectable } from '@angular/core';
import { onINP } from 'web-vitals/attribution';
import { GtmInitializerService } from './gtm-initializer.service';

@Injectable({
    providedIn: 'root',
})
export class INPMeasuringService {
    public onINP = onINP;

    public _sendToGoogleAnalytics({ name, value, id, attribution }: any): void {
        // Destructure the attribution object:
        const { eventEntry, eventTarget, eventType, loadState } = attribution;

        // Get timings from the event timing entry:
        const { startTime, processingStart, processingEnd, duration, interactionId } = eventEntry;

        const eventParams = {
            // The page's INP value:
            metric_inp_value: value,
            // A unique ID for the page session, which is useful
            // for computing totals when you group by the ID.
            metric_id: id,
            // The event target (a CSS selector string pointing
            // to the element responsible for the interaction):
            metric_inp_event_target: eventTarget,
            // The type of event that triggered the interaction:
            metric_inp_event_type: eventType,
            // Whether the page was loaded when the interaction
            // took place. Useful for identifying startup versus
            // post-load interactions:
            metric_inp_load_state: loadState,
            // The time (in milliseconds) after page load when
            // the interaction took place:
            metric_inp_start_time: startTime,
            // When processing of the event callbacks in the
            // interaction started to run:
            metric_inp_processing_start: processingStart,
            // When processing of the event callbacks in the
            // interaction finished:
            metric_inp_processing_end: processingEnd,
            // The total duration of the interaction. Note: this
            // value is rounded to 8 milliseconds of granularity:
            metric_inp_duration: duration,
            // The interaction ID assigned to the interaction by
            // the Event Timing API. This could be useful in cases
            // where you might want to aggregate related events:
            metric_inp_interaction_id: interactionId,
        };

        GtmInitializerService.inited$.subscribe((isInit) => {
            if (isInit) {
                const theWindow = window as any;
                theWindow.dataLayer.push({
                    event: 'INP',
                    eventParams,
                });
            }
        });
    }
}
