<eb-form-item
  #container
  [label]="label"
  [size]="size"
  [theme]="theme"
  [onlyIcon]="onlyIcon"
  [required]="required"
  prefixIcon="calendar-month"
  suffixIcon="chevron-down"
  [isClearIcon]="!!displayValue"
  (clearInput)="clear()"
  (click)="onClickInputBox()"
>
  <input type="text" class="eb-input" [ngModel]="displayValue" readonly [placeholder]="placeholder | transloco">

</eb-form-item>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="isOpen"
  (detach)="close()"
  (overlayOutsideClick)="onOverlayOutsideClick($event)"
>
  <nz-date-picker [ngModel]="value" (ngModelChange)="select($event)" nzInline nzDropdownClassName="eb-eb-date-range-picker__control" />
</ng-template>
