import { Injectable, inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { isPlatformBrowser } from '@angular/common';

interface Language {
    id: string;
    label: string;
}

@Injectable({ providedIn: 'root' })
export class InternationalizationService {
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _translocoService = inject(TranslocoService);
    private readonly _router = inject(Router);

    availableLanguages: Language[] = [];
    activeLanguage!: string;
    changingLanguage = false;

    public get isEnglishActive(): boolean {
        return this.activeLanguage === 'en';
    }

    constructor() {
        this.availableLanguages = this._translocoService.getAvailableLangs() as Language[];
    }

    public setLanguage(language: string, redirect = false): any {
        this.activeLanguage = language;
        this._translocoService.setActiveLang(language);

        if (redirect) {
            if (isPlatformBrowser(this._platformId)) {
                // uruchomienie kodu redirectu w nowym wątku; cel: poprawa INP
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                new Promise(() => {
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    setTimeout(() => this.redirectToLanguageVersion(this._router.url), 0);
                });
            } else {
                this.redirectToLanguageVersion(this._router.url)
            }
        }
    }

    public redirectToLanguageVersion(route: string): Promise<boolean> | boolean {
        const isEnRoute = route.split('/')[1] === 'en';

        if (this.isEnglishActive && !isEnRoute) {
            return this._router.navigateByUrl(`/en${route === '/' ? '' : route}`);
        } else if (!this.isEnglishActive && isEnRoute) {
            return this._router.navigateByUrl(route.slice(3));
        }

        return true;
    }
}
