import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { REQUEST, RESPONSE } from '../../../../apps/portal/src/consts/request-response.const';

@Injectable({
    providedIn: 'root',
})
export class CookiesService {
    private readonly _document = inject(DOCUMENT);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _request = inject(REQUEST, { optional: true });
    private readonly _response = inject(RESPONSE, { optional: true });

    public getCookieValue(cookieName: CookieName): string {
        const cookies = this._getAllCookies().map((cookie) => cookie.split('=') as [string, string]);
        const cookiesMap = new Map<string, string>(cookies);
        return decodeURIComponent(cookiesMap.get(cookieName) || '');
    }

    public setCookieValue(cookieName: CookieName, cookieValue: string, cookieParams?: ICookieParams): void {
        if (isPlatformServer(this._platformId)) {
            this._response?.cookie(cookieName, cookieValue, this._mapCookieParamsToServerCoookieOptions(cookieParams));
            return;
        }

        const cookie = `${cookieName}=${encodeURIComponent(cookieValue)}${this._mapCookieParams(cookieParams)}`;
        document.cookie = cookie;
    }

    private _mapCookieParamsToServerCoookieOptions(
        cookieParams: ICookieParams | undefined,
    ): import('express-serve-static-core').CookieOptions {
        return {
            domain: cookieParams?.domain ? this._getDomaina() : undefined,
            path: cookieParams?.path,
            expires: cookieParams?.expires,
        };
    }

    private _mapCookieParams(cookieParams: ICookieParams | undefined): string {
        if (!cookieParams) {
            return '';
        }

        const paramParts = [''];

        if (cookieParams.domain) {
            paramParts.push(`domain=${this._getDomaina()}`);
        }

        if (cookieParams.path) {
            paramParts.push(`path=${cookieParams.path}`);
        }

        if (cookieParams.expires && cookieParams.expires instanceof Date) {
            paramParts.push(`expires=${cookieParams.expires.toUTCString()}`);
        }

        if (cookieParams.secure) {
            paramParts.push(`secure=${cookieParams.secure}`);
        }

        if (cookieParams.sameSite) {
            paramParts.push(`samesite=${cookieParams.sameSite}`);
        }

        if (cookieParams.partitioned) {
            paramParts.push('Partitioned');
        }

        return paramParts.join(';');
    }

    public clearAllCookies(exceptions: string[] = []): void {
        if (isPlatformBrowser(this._platformId)) {
            const cookies = this._getAllCookies();

            cookies.forEach((cookie) => {
                const cookieName = cookie.split('=')[0];

                if (!exceptions.includes(cookieName)) {
                    const hostnameParts = window.location.hostname.split('.');
                    while (hostnameParts.length > 0) {
                        const cookieBase =
                            encodeURIComponent(cookieName) +
                            '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=' +
                            hostnameParts.join('.') +
                            ' ;path=';
                        const pathSplit = window.location.pathname.split('/');
                        document.cookie = cookieBase + '/';

                        while (pathSplit.length > 0) {
                            document.cookie = cookieBase + pathSplit.join('/');
                            pathSplit.pop();
                        }

                        hostnameParts.shift();
                    }
                }
            });
        }
    }

    private _getAllCookies(): string[] {
        if (isPlatformBrowser(this._platformId)) {
            return this._document?.cookie?.split('; ') ?? [];
        } else {
            return this._request?.headers?.cookie?.split('; ') ?? [];
        }
    }

    private _getDomaina(): string {
        const hostname = isPlatformServer(this._platformId) ? this._request?.hostname : window.location.hostname;

        if (hostname === 'localhost') {
            return hostname;
        }

        return '.' + hostname?.split('.').slice(-2).join('.');
    }
}

export enum CookieName {
    GtmOrganizerIds = '_mco',
    LoggedUser = 'eBilet.lmc',
    CookieAgreeOld = 'eb_cookie_agree_0124',
    CookieAgree = 'eb_cookie_agree_0324',
    Customer = 'eBilet.customer',
    Session = 'eBilet.session',
    Branding = 'eb_branding',
    Tradedoubler = 'TRADEDOUBLER',
    TradedoublerPartnerId = 'TRADEDOUBLER_PARTNER_ID',
    CookiebotConsent = 'CookieConsent',
    BasketExpiration = 'eb_basket_expiration_date',
}

export interface ICookieParams {
    path?: string;
    domain?: boolean;
    expires?: Date;
    secure?: boolean;
    sameSite?: 'None' | 'Strict' | 'Lax';
    partitioned?: boolean;
}
