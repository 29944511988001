import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Meta as MetaService, Title as TitleService } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { REQUEST } from '../../consts/request-response.const';
import { environment } from '../../environments/environment';
import { ISyneriseMeta, ISeoMeta, SyneriseMetaFieldName } from '../rest-api/models/seo-info.model';

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    private readonly _document = inject(DOCUMENT);
    private readonly _titleService = inject(TitleService);
    private readonly _metaService = inject(MetaService);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _request = inject(REQUEST, { optional: true });
    private readonly _router = inject(Router);
    readonly defaultTitle = 'eBilet';

    public set(seoInfo: ISeoMeta | null): void {
        if (seoInfo) {
            this._titleService.setTitle(seoInfo.title || this.defaultTitle);

            if (seoInfo.description) {
                this._metaService.updateTag({ name: 'description', content: seoInfo.description });
            } else {
                this._metaService.removeTag('description');
            }

            const robotsContent = [seoInfo.noindex ? 'noindex' : 'index', seoInfo.nofollow ? 'nofollow' : 'follow'];

            const isEnglishVersion = this._router.url.split('/')[1] === 'en';
            this._metaService.updateTag({
                name: 'robots',
                content: isEnglishVersion ? 'noindex, nofollow' : robotsContent.join(', '),
            });

            this._updateSyneriseMeta(seoInfo.syneriseMeta);
        } else {
            this._titleService.setTitle(this.defaultTitle);
            this._metaService.removeTag('description');
        }

        this._setCanonicalLink(seoInfo?.canonicalUrl);
    }

    private _setCanonicalLink(canonicalUrl: string | undefined): void {
        if (this._document) {
            let link: HTMLLinkElement | null = this._document.querySelector('link[rel="canonical"]');

            if (!link) {
                link = this._document.createElement('link');
                link.setAttribute('rel', 'canonical');
                this._document.head.appendChild(link);
            }

            if (canonicalUrl) {
                link.setAttribute('href', canonicalUrl);
                return;
            }

            if (isPlatformServer(this._platformId)) {
                const hostname = this._request?.header('x-webrock-request-host') || this._request?.hostname;
                link.setAttribute(
                    'href',
                    `${this._request?.protocol}://${hostname}${this._removeEnglishPrefixIfExists(
                        this._request?.path ?? '',
                    )}`,
                );
            } else {
                const location = this._document.location;
                link.setAttribute(
                    'href',
                    `${location.protocol}//${location.hostname}${this._removeEnglishPrefixIfExists(location.pathname)}`,
                );
            }
        }
    }

    private _removeEnglishPrefixIfExists(path: string): string {
        const pathSplit = path.split('/');

        if (pathSplit.length && pathSplit[1] === 'en') {
            return pathSplit.length === 2 ? '/' : path.slice(3);
        }
        return path;
    }

    private _updateSyneriseMeta(syneriseMeta: ISyneriseMeta | undefined): void {
        SYNERISE_META_MAP.forEach((metaMap) => {
            if (
                syneriseMeta &&
                (syneriseMeta[metaMap.fieldName] || metaMap.fieldName === SyneriseMetaFieldName.productCustomLabel0)
            ) {
                let value = syneriseMeta[metaMap.fieldName] || 'none';
                if (metaMap.fieldName === SyneriseMetaFieldName.ogImage) {
                    value = environment.siteUrl + environment.mediaUrl + value;
                }

                this._metaService.updateTag({ property: metaMap.propertyName, content: value as string });
            } else {
                this._metaService.removeTag(`property='${metaMap.propertyName}'`);
            }
        });
    }
}

const SYNERISE_META_MAP: readonly ISyneriseMetaMap[] = [
    { fieldName: SyneriseMetaFieldName.fbAppId, propertyName: 'fb:app_id' },
    { fieldName: SyneriseMetaFieldName.ogTitle, propertyName: 'og:title' },
    { fieldName: SyneriseMetaFieldName.ogType, propertyName: 'og:type' },
    { fieldName: SyneriseMetaFieldName.ogSiteName, propertyName: 'og:site_name' },
    { fieldName: SyneriseMetaFieldName.ogDescription, propertyName: 'og:description' },
    { fieldName: SyneriseMetaFieldName.ogUrl, propertyName: 'og:url' },
    { fieldName: SyneriseMetaFieldName.ogImage, propertyName: 'og:image' },

    { fieldName: SyneriseMetaFieldName.productCategory, propertyName: 'product:category' },
    { fieldName: SyneriseMetaFieldName.productOriginalPriceAmount, propertyName: 'product:original_price:amount' },
    { fieldName: SyneriseMetaFieldName.productOriginalPriceCurrency, propertyName: 'product:original_price:currency' },
    { fieldName: SyneriseMetaFieldName.productPriceAmount, propertyName: 'product:price:amount' },
    { fieldName: SyneriseMetaFieldName.productPriceCurrency, propertyName: 'product:price:currency' },
    { fieldName: SyneriseMetaFieldName.productRetailerPartNo, propertyName: 'product:retailer_part_no' },
    { fieldName: SyneriseMetaFieldName.productExpirationTime, propertyName: 'product:expiration_time' },
    { fieldName: SyneriseMetaFieldName.productBrand, propertyName: 'product:brand' },
    { fieldName: SyneriseMetaFieldName.productAvailability, propertyName: 'product:availability' },
    { fieldName: SyneriseMetaFieldName.productCondition, propertyName: 'product:condition' },

    { fieldName: SyneriseMetaFieldName.productRetailerItemId, propertyName: 'product:retailer_item_id' },
    { fieldName: SyneriseMetaFieldName.productCustomLabel0, propertyName: 'product:custom_label_0' },
    { fieldName: SyneriseMetaFieldName.productCustomLabel1, propertyName: 'product:custom_label_1' },
    { fieldName: SyneriseMetaFieldName.productCustomLabel2, propertyName: 'product:custom_label_2' },
    { fieldName: SyneriseMetaFieldName.productCustomLabel3, propertyName: 'product:custom_label_3' },
    { fieldName: SyneriseMetaFieldName.productCustomLabel4, propertyName: 'product:custom_label_4' },
];

interface ISyneriseMetaMap {
    fieldName: SyneriseMetaFieldName;
    propertyName: string;
}
