/* eslint-disable @typescript-eslint/no-empty-function */
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    ViewChild,
    ViewEncapsulation,
    forwardRef,
    inject,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { differenceInCalendarDays, lightFormat } from 'date-fns';
import { DateRangeService } from 'libs/ui-date-picker/date-range.service';
import { EbInputSize, EbInputTheme } from 'libs/ui-form-item/src/lib/form-item/form-item.component';
import { DateParamWithRange } from '../date-param-with-range.model';
import { DateParams } from '../date-range-panel/date-range-panel.component';

export type DateRangeValue = [Date, Date];

@Component({
    selector: 'eb-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EbDateRangePickerComponent), multi: true }],
})
export class EbDateRangePickerComponent implements ControlValueAccessor {
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _dateRangeService = inject(DateRangeService);
    public readonly elementRef = inject(ElementRef);

    private _today = new Date();
    private _value: DateRangeValue | null = null;
    private _displayValue = '';
    private _originEl: CdkOverlayOrigin;
    private _origin: CdkOverlayOrigin;
    isOpen = false;

    protected get value(): DateRangeValue | null {
        return this._value;
    }

    protected get displayValue(): string {
        return this._displayValue;
    }

    @Input() label = '';
    @Input() size: EbInputSize = 'default';
    @Input() theme: EbInputTheme = 'dark';
    @Input() placeholder: string | undefined;
    @Input() public set origin(origin: CdkOverlayOrigin | undefined) {
        if (origin) {
            this._origin = origin;
        } else {
            this._origin = this._originEl;
        }
    }

    public get origin(): CdkOverlayOrigin {
        return this._origin;
    }

    disabledDate = (current: Date): boolean => differenceInCalendarDays(current, this._today) < 0;

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    private _onTouched = () => {};
    private _onChange: (value: DateRangeValue | DateParams | null) => void = () => {};

    @HostBinding('class.eb-date-range-picker') protected get isPicker(): boolean {
        return true;
    }

    @HostBinding('class.eb-date-range-picker--only-icon') @Input() onlyIcon = false;

    @HostListener('click') protected onClickInputBox(): void {
        this.open();
    }

    @ViewChild('overlayPanel') readonly overlayPanel!: ElementRef;

    constructor() {
        this._originEl = new CdkOverlayOrigin(this.elementRef);
        this._origin = this._originEl;
    }

    public writeValue(obj: DateRangeValue | DateParams): void {
        const newDsiplayValue = this._formatRange(obj);
        if (this._displayValue !== newDsiplayValue) {
            this._displayValue = newDsiplayValue;

            if (Array.isArray(obj)) {
                this._value = obj;
            } else {
                this._value = this._dateRangeService.mapDateParamToDateRange(obj) as DateRangeValue;
            }

            this.changeDetectorRef.markForCheck();
        }
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    protected clear(): void {
        this.select(null);
    }

    protected select(value: DateRangeValue | null): void {
        this._value = value;
        this._displayValue = this._formatRange(this.value);
        this.close();
        this._onChange(value);
    }

    protected selectStiff(paramWithRange: DateParamWithRange): void {
        this._value = paramWithRange.range;
        this._displayValue = this._formatRange(paramWithRange.range);
        this.close();
        this._onChange(paramWithRange.param);
    }

    private _formatRange(value: DateRangeValue | DateParams | null): string {
        if (value?.length !== 2) {
            value = this._dateRangeService.mapDateParamToDateRange(value as DateParams) as DateRangeValue;
        }

        if (value) {
            try {
                const dateFormat = 'dd.MM.yyyy';
                const start = lightFormat((value as DateRangeValue)[0], dateFormat);
                const end = lightFormat((value as DateRangeValue)[1], dateFormat);
                return start === end ? start : `${start} - ${end}`;
            } catch {
                return '';
            }
        }
        return '';
    }

    protected onOverlayOutsideClick(event: MouseEvent): void {
        if (!(this.elementRef.nativeElement as any).contains(event.target)) {
            this.close();
        }
    }

    protected open(): void {
        this.isOpen = true;
    }

    protected close(): void {
        this.isOpen = false;
    }

    public invokeClick(): void {
        this.elementRef.nativeElement.click();
    }
}
