/* eslint-disable @typescript-eslint/no-empty-function */
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    ViewEncapsulation,
    forwardRef,
    inject,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { lightFormat } from 'date-fns';
import { EbInputSize, EbInputTheme } from '../../../../ui-form-item/src/lib/form-item/form-item.component';

@Component({
    selector: 'eb-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EbDatePickerComponent), multi: true }],
})
export class EbDatePickerComponent {
    private readonly _elementRef = inject(ElementRef);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);

    private _value: Date | null = null;
    private _displayValue = '';
    private _originEl: CdkOverlayOrigin;
    private _origin: CdkOverlayOrigin;
    isOpen = false;

    protected get value(): Date | null {
        return this._value;
    }

    protected get displayValue(): string {
        return this._displayValue;
    }

    @Input() required = false;
    @Input() label = '';
    @Input() size: EbInputSize = 'default';
    @Input() theme: EbInputTheme = 'dark';
    @Input() placeholder = 'libs.date-range-picker.selectDate';
    @Input() public set origin(origin: CdkOverlayOrigin | undefined) {
        if (origin) {
            this._origin = origin;
        } else {
            this._origin = this._originEl;
        }
    }

    get origin(): CdkOverlayOrigin {
        return this._origin;
    }

    @Output() clearInput = new EventEmitter<void>();

    private _onTouched: () => void = () => {};
    private _onChange: (value: Date | null) => void = () => {};

    @HostBinding('class.eb-date-picker') protected get isPicker(): boolean {
        return true;
    }

    @HostBinding('class.eb-date-picker--only-icon') @Input() onlyIcon = false;

    @HostListener('click') protected onClickInputBox(): void {
        this.open();
    }

    constructor() {
        this._originEl = new CdkOverlayOrigin(this._elementRef);
        this._origin = this._originEl;
    }

    public writeValue(obj: Date): void {
        const newDsiplayValue = this._formatRange(obj);
        if (this._displayValue !== newDsiplayValue) {
            this._displayValue = newDsiplayValue;
            this._value = obj;
            this._changeDetectorRef.markForCheck();
        }
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    protected clear(): void {
        this.select(null);
        this.clearInput.emit();
    }

    protected select(value: Date | null): void {
        this._value = value;
        this._displayValue = this._formatRange(this.value);
        this.close();
        this._onChange(value);
    }

    private _formatRange(value: Date | null): string {
        if (value) {
            try {
                const dateFormat = 'dd.MM.yyyy';
                return lightFormat(value, dateFormat);
            } catch {
                return '';
            }
        }
        return '';
    }

    protected onOverlayOutsideClick(event: MouseEvent): void {
        if (!(this._elementRef.nativeElement as any).contains(event.target)) {
            this.close();
        }
    }

    protected open(): void {
        this.isOpen = true;
    }

    protected close(): void {
        this.isOpen = false;
    }
}
