<eb-form-item #container [label]="label" [size]="size" [theme]="theme" [onlyIcon]="onlyIcon"
  prefixIcon="calendar-month"
  suffixIcon="chevron-down"
  [isClearIcon]="!!displayValue"
  (clearInput)="clear()"
  (click)="onClickInputBox()"
>
  <input type="text" class="eb-input" [ngModel]="displayValue" readonly [placeholder]="placeholder || 'libs.date-range-picker.selectDate' | transloco">

</eb-form-item>


<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="isOpen"
  (detach)="close()"
  (overlayOutsideClick)="onOverlayOutsideClick($event)"
>
  <div class="eb-overlay-panel hm-60" #overlayPanel>
    <div class="eb-date-range-picker__overlay flex">
      <eb-date-range-panel [ngModel]="value" (ngModelChange)="select($event)" (selectedChangedStiff)="selectStiff($event)" />
    </div>
  </div>
</ng-template>
