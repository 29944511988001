export interface IHomePage {
    seoMeta: ISeoMeta;
    externalId?: number;
    seoBoxContent?: string;
    seoBoxLinks?: ISeoBoxLinks[];
}

export interface ISeoMeta {
    keywords: string;
    title: string;
    description: string;
    robots?: string;
    robots2?: string;
    noseo?: string;
    noindex?: boolean;
    nofollow?: boolean;
    syneriseMeta?: ISyneriseMeta;
    canonicalUrl?: string;
    microDescription?: string;
}

export enum SyneriseMetaFieldName {
    productOriginalPriceAmount = 'productOriginalPriceAmount',
    productOriginalPriceCurrency = 'productOriginalPriceCurrency',
    productPriceAmount = 'productPriceAmount',
    productPriceCurrency = 'productPriceCurrency',
    productRetailerPartNo = 'productRetailerPartNo',
    productExpirationTime = 'productExpirationTime',
    productBrand = 'productBrand',
    productAvailability = 'productAvailability',
    productCondition = 'productCondition',
    productRetailerItemId = 'productRetailerItemId',
    productCustomLabel0 = 'productCustomLabel0',
    productCustomLabel1 = 'productCustomLabel1',
    productCustomLabel2 = 'productCustomLabel2',
    productCustomLabel3 = 'productCustomLabel3',
    productCustomLabel4 = 'productCustomLabel4',
    productCategory = 'productCategory',
    fbAppId = 'fbAppId',
    ogTitle = 'ogTitle',
    ogType = 'ogType',
    ogSiteName = 'ogSiteName',
    ogDescription = 'ogDescription',
    ogUrl = 'ogUrl',
    ogImage = 'ogImage',
}
export interface ISyneriseMeta {
    productOriginalPriceAmount?: string;
    productOriginalPriceCurrency?: string;
    productPriceAmount?: string;
    productPriceCurrency?: string;
    productRetailerPartNo?: string;
    productExpirationTime?: string;
    productBrand?: string;
    productAvailability?: string;
    productCondition?: string;
    productRetailerItemId?: string;
    productCustomLabel0?: string;
    productCustomLabel1?: string;
    productCustomLabel2?: string;
    productCustomLabel3?: string;
    productCustomLabel4?: string;
    productCategory?: string;
    fbAppId?: string;
    ogTitle?: string;
    ogType?: string;
    ogSiteName?: string;
    ogDescription?: string;
    ogUrl?: string;
    ogImage?: string;
}

export interface ISeoBoxLinks {
    name: string;
    links: ISeoBoxLink[];
}

export interface ISeoBoxLink {
    anchor: string;
    url: string;
    target: string;
    nofollow: boolean;
}
