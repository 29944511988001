import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ColorHelper } from '../helpers/color.helper';
import { IFrame, IFrameTypeEnum } from '../rest-api/models/iframe';
import { BackgroundService } from './background.service';
import { BrandingService } from './branding.service';

@Injectable({
    providedIn: 'root',
})
export class IframeService {
    private readonly _backgroundService = inject(BackgroundService);
    private readonly _brandingService = inject(BrandingService);
    @Inject(DOCUMENT) private _document = inject(DOCUMENT);
    private _renderer: Renderer2 = inject(RendererFactory2).createRenderer(this._document.body, null);

    private _iframe: IFrame | undefined;
    private _iframeSubject = new ReplaySubject<IFrame | undefined>(1);

    readonly darkIframe = {
        textColor: '#FFFFFF',
        lightColor: '#E6EFD7',
        mediumColor: '#D4DDC5',
        darkColor: '#8E9389',
        borderColor: '#4F5549',
        bgColor: '#1a1a1a',
        purpleButtonColor: 'var(--eb-btn-color)',
        ghostBgColor: 'transparent',
        ticketPanelBg: '#000000',
        cookiesBg: 'rgba(0,0,0,0.4)',
    };

    public get iframe$(): Observable<IFrame | undefined> {
        return this._iframeSubject.asObservable();
    }

    public setIframe(
        domain?: string,
        iframetype?: IFrameTypeEnum,
        primaryColor?: string,
        activeBtnTxtColor?: string,
        footerVisible?: string,
    ): void {
        this._iframe = this._getIframeObject(domain, iframetype, primaryColor, activeBtnTxtColor, footerVisible);
        this._setColors();
        this._shareIframe();
    }

    private _shareIframe(): void {
        this._iframeSubject.next(this._iframe);
    }

    private _getIframeObject(
        domain?: string,
        iframetype?: IFrameTypeEnum,
        primaryColor?: string,
        activeBtnTxtColor?: string,
        footerVisible?: string,
    ): IFrame | undefined {
        if (domain) {
            return {
                domain,
                type: iframetype ?? IFrameTypeEnum.TRANSPARENT,
                primaryColor,
                activeBtnTxtColor,
                footerVisible: footerVisible === 'true',
            } as IFrame;
        }

        return;
    }

    private _setColors(): void {
        if (this._iframe) {
            this._renderer.setStyle(this._document.body, 'overflow-y', 'hidden', 2);

            if (this._iframe.type === IFrameTypeEnum.DARK) {
                // background
                this._backgroundService.dark();

                // text global
                this._renderer.setStyle(this._document.body, '--eb-header-color', this.darkIframe.textColor, 2);
                this._renderer.setStyle(this._document.body, '--eb-text-color', this.darkIframe.textColor, 2);

                // ticket panel
                this._renderer.setStyle(this._document.body, '--eb-title-event-group-bg', this.darkIframe.bgColor, 2);
                this._renderer.setStyle(
                    this._document.body,
                    '--eb-ticket-panel-sold-out-color',
                    this.darkIframe.darkColor,
                    2,
                );
                this._renderer.setStyle(this._document.body, '--eb-ticket-panel-bg', this.darkIframe.ticketPanelBg, 2);
                this._renderer.setStyle(
                    this._document.body,
                    '--eb-ticket-panel-bg-hover',
                    this.darkIframe.ticketPanelBg,
                    2,
                );
                this._renderer.setStyle(
                    this._document.body,
                    '--eb-ticket-panel-border-color',
                    this.darkIframe.borderColor,
                    2,
                );

                // buttons
                this._renderer.setStyle(this._document.body, '--eb-btn-bg', this.darkIframe.ghostBgColor, 2);
                this._renderer.setStyle(this._document.body, '--eb-btn-bg-hover', this.darkIframe.ghostBgColor, 2);
                this._renderer.setStyle(this._document.body, '--eb-btn-color', 'white', 2);
                this._renderer.setStyle(this._document.body, '--eb-btn-secondary-bg', this.darkIframe.lightColor, 2);
                this._renderer.setStyle(
                    this._document.body,
                    '--eb-btn-secondary-bg-hover',
                    this.darkIframe.mediumColor,
                    2,
                );
                this._renderer.setStyle(this._document.body, '--eb-btn-purple-color', this.darkIframe.bgColor, 2);
                this._renderer.setStyle(this._document.body, '--eb-btn-purple-bg', this.darkIframe.lightColor, 2);
                this._renderer.setStyle(
                    this._document.body,
                    '--eb-btn-purple-bg-hover',
                    this.darkIframe.mediumColor,
                    2,
                );
                this._renderer.setStyle(this._document.body, '--eb-btn-ghost-color', this.darkIframe.textColor, 2);
                this._renderer.setStyle(
                    this._document.body,
                    '--eb-btn-ghost-bg-hover',
                    this.darkIframe.ghostBgColor,
                    2,
                );

                // calendar
                this._renderer.setStyle(
                    this._document.body,
                    '--eb-calendar-border-color',
                    this.darkIframe.borderColor,
                    2,
                );
                this._renderer.setStyle(
                    this._document.body,
                    '--eb-calendar-item-with-data-background',
                    this.darkIframe.bgColor,
                    2,
                );
                this._renderer.setStyle(this._document.body, '--eb-calendar-tabset-bg', this.darkIframe.bgColor, 2);
                this._renderer.setStyle(
                    this._document.body,
                    '--eb-calendar-tabset-bg-active',
                    this.darkIframe.bgColor,
                    2,
                );
                this._renderer.setStyle(this._document.body, '--eb-calendar-color', this.darkIframe.darkColor, 2);
                this._renderer.setStyle(
                    this._document.body,
                    '--eb-calendar-nav-label-color',
                    this.darkIframe.textColor,
                    2,
                );
                this._renderer.setStyle(
                    this._document.body,
                    '--eb-calendar-view-header-color',
                    this.darkIframe.textColor,
                    2,
                );
                this._renderer.setStyle(
                    this._document.body,
                    '--eb-calendar-color-active',
                    this.darkIframe.lightColor,
                    2,
                );
                this._renderer.setStyle(this._document.body, '--tab-nav-border-color', this.darkIframe.borderColor, 2);
                this._renderer.setStyle(
                    this._document.body,
                    '--tab-header-border-color',
                    this.darkIframe.lightColor,
                    2,
                );

                // tooltip
                this._renderer.setStyle(this._document.body, '--eb-tooltip-color', this.darkIframe.textColor, 2);
                this._renderer.setStyle(this._document.body, '--eb-tooltip-border', this.darkIframe.borderColor, 2);
                this._renderer.setStyle(this._document.body, '--eb-tooltip-bg', this.darkIframe.ticketPanelBg, 2);

                // cookies
                this._renderer.setStyle(this._document.body, '--eb-cookies-bg', this.darkIframe.cookiesBg, 2);
                this._renderer.setStyle(this._document.body, '--eb-cookies-color', this.darkIframe.textColor, 2);
                this._renderer.setStyle(this._document.body, '--eb-link-color', this.darkIframe.textColor, 2);
                this._renderer.setStyle(this._document.body, '--eb-link-color-hover', this.darkIframe.lightColor, 2);
            }

            if (this._iframe.primaryColor) {
                this._brandingService.setButtonBackgroundColor(ColorHelper.hashColor(this._iframe.primaryColor));
            }

            if (this._iframe.activeBtnTxtColor) {
                this._brandingService.setButtonFontColor(ColorHelper.hashColor(this._iframe.activeBtnTxtColor));
            }
        }
    }

    public sendHeightPostMessage(height: number, unit: 'px' | 'vh' | '%' = 'px'): void {
        window.parent.postMessage({ height: height + unit }, '*');
    }
}
