/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  OnInit,
  Output,
  ViewEncapsulation,
  forwardRef,
  inject, PLATFORM_ID
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { differenceInCalendarDays } from 'date-fns';
import { DateRangeService } from 'libs/ui-date-picker/date-range.service';
import { DateParamWithRange } from '../date-param-with-range.model';
import { isPlatformBrowser } from '@angular/common';

export type DateRangeValue = [Date, Date];

export enum DateParams {
    TODAY = 'dzis',
    TOMORROW = 'jutro',
    CURRENT_WEEK = 'ten-tydzien',
    NEXT_WEEK = 'nastepny-tydzien',
    NEAREST_WEEKEND = 'ten-weekend',
    NEXT_WEEKEND = 'nastepny-weekend',
    CURRENT_MONTH = 'ten-miesiac',
    NEXT30DAYS = '30dni',
}

@Component({
    selector: 'eb-date-range-panel',
    templateUrl: './date-range-panel.component.html',
    styleUrls: ['./date-range-panel.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EbDateRangePanelComponent), multi: true }],
})
export class EbDateRangePanelComponent implements ControlValueAccessor, OnInit {
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _dateRangeService = inject(DateRangeService);

    private readonly _today = new Date();
    private _value: DateRangeValue | DateParams | null = null;
    protected isOpen = false;
    readonly DateParams = DateParams;
    scrollShift = 150;
    leftDirection = -1;
    rightDirection = 1;

    protected get value(): DateRangeValue | DateParams | null {
        return this._value;
    }

    @Output() readonly selectedChanged = new EventEmitter<DateRangeValue | null>();
    @Output() readonly selectedChangedStiff = new EventEmitter<DateParamWithRange>();

    disabledDate = (current: Date): boolean => differenceInCalendarDays(current, this._today) < 0;

    private _onTouched = () => {};
    private _onChange: (value: DateRangeValue | DateParams | null) => void = () => {};

    @HostBinding('class.eb-date-range-panel') protected get isPicker(): boolean {
        return true;
    }

    public ngOnInit(): void {
        // inicjowanie nz-range-pickera w osobnym wątku; cel: poprawa INP
        if (isPlatformBrowser(this._platformId)) {
            new Promise(() => {
                setTimeout(() => {
                    this.isOpen = true;
                    this._changeDetectorRef.markForCheck();
                }, 20);
            });
        } else {
            this.isOpen = true;
            this._changeDetectorRef.markForCheck();
        }
    }

    public writeValue(obj: DateRangeValue): void {
        this._value = obj;
        this._changeDetectorRef.markForCheck();
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public clear(): void {
        this.select(null);
    }

    protected disableEarlierDates(clickedDate: (Date | null)[]): void {
        this.disabledDate = (current: Date): boolean => differenceInCalendarDays(current, clickedDate[0] as Date) < 0;
    }

    protected select(value: DateRangeValue | null): void {
        if (value && value[0] > value[1]) {
            value.reverse();
        }

        const isStiffRange: DateParams | null = this._dateRangeService.isDateRangeStiff(value);
        if (isStiffRange) {
            this.selectStiff(isStiffRange);
            return;
        }

        this._value = value;
        this._onChange(value);
        if (this.selectedChanged.observed) {
            this.selectedChanged.emit(value);
        }
    }

    protected selectStiff(param: DateParams): void {
        const paramWithRange: DateParamWithRange = {
            param,
            range: this._dateRangeService.mapDateParamToDateRange(param) as DateRangeValue,
        };
        this._value = paramWithRange.param;
        this._onChange(paramWithRange.param);
        if (this.selectedChangedStiff.observed) {
            this.selectedChangedStiff.emit(paramWithRange);
        }
    }
}
