export class ColorHelper {
    static darken(hex: string, amount: number): string {
        return this._darkenLigten(hex, amount, true);
    }

    static lighten(hex: string, amount: number): string {
        return this._darkenLigten(hex, amount, false);
    }

    static rgbToHex(r: number, g: number, b: number): string {
        return `#${this._componentToHex(r)}${this._componentToHex(g)}${this._componentToHex(b)}`;
    }

    static hexToRgb(hex: string): { r: number; g: number; b: number } | null {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        if (result) {
            return {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
            };
        }

        return null;
    }

    private static _darkenLigten(hex: string, amount: number, darken = false): string {
        const rgb = this.hexToRgb(hex);
        if (!rgb) {
            return hex;
        }

        if (darken) {
            amount = amount * -1;
        }

        const r = Math.min(Math.max(rgb.r + amount, 0), 255);
        const g = Math.min(Math.max(rgb.g + amount, 0), 255);
        const b = Math.min(Math.max(rgb.b + amount, 0), 255);

        return this.rgbToHex(r, g, b);
    }

    private static _componentToHex(n: number): string {
        return n.toString(16).padStart(2, '0');
    }

    static hashColor(color: string | undefined) {
        if (!color) {
            return color;
        }

        if (color[0] !== '#') {
            color = `#${color}`;
        }

        return color;
    }
}
