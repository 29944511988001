import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, ViewEncapsulation } from '@angular/core';

export type EbButtonType = 'primary' | 'secondary' | 'default' | 'text' | 'light' | null;
export type EbButtonSize = 'large' | 'default' | 'small';
export type EbButtonColor = 'purple' | 'link' | null;

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[eb-button], a[eb-button], span[eb-button]',
    exportAs: 'ebButton',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.less'],
})
export class EbButtonComponent {
    @Input() ebType: EbButtonType = null;
    @Input() ebSize: EbButtonSize = 'default';
    @Input() ebGhost = false;
    @Input() ebColor: EbButtonColor = null;
    @Input() ebOnlyIcon = false;
    @Input() disabled = false;
    @Input() noBlur = false;
    @Input() active = false;

    @HostBinding('class.eb-btn') protected get isButton(): boolean {
        return true;
    }

    @HostBinding('class.eb-btn-primary') protected get isPrimary(): boolean {
        return this.ebType === 'primary';
    }

    @HostBinding('class.eb-btn-secondary') protected get isSecondary(): boolean {
        return this.ebType === 'secondary';
    }

    @HostBinding('class.eb-btn-light') protected get isLight(): boolean {
        return this.ebType === 'light';
    }

    @HostBinding('class.eb-btn-text') protected get isText(): boolean {
        return this.ebType === 'text';
    }

    @HostBinding('class.eb-btn-lg') protected get isLarge(): boolean {
        return this.ebSize === 'large';
    }

    @HostBinding('class.eb-btn-sm') protected get isSmall(): boolean {
        return this.ebSize === 'small';
    }

    @HostBinding('class.eb-btn-color--purple') protected get isColorPurple(): boolean {
        return this.ebColor === 'purple';
    }

    @HostBinding('class.eb-btn-color--link') protected get isColorLink(): boolean {
        return this.ebColor === 'link';
    }

    @HostBinding('class.eb-btn-ghost') protected get isGhost(): boolean {
        return this.ebGhost;
    }

    @HostBinding('class.eb-btn-only-icon') protected get isOnlyIcon(): boolean {
        return this.ebOnlyIcon;
    }

    @HostBinding('attr.tabindex') protected get getTabIndex(): number | null {
        return this.disabled ? -1 : null;
    }

    @HostBinding('attr.disabled') protected get isDisabled(): boolean | null {
        return this.disabled || null;
    }

    @HostBinding('class.eb-btn--no-blur') protected get isNoBlur(): boolean {
        return this.noBlur;
    }

    @HostBinding('class.eb-btn-active') protected get isActive(): boolean {
        return this.active;
    }

    @HostListener('click', ['$event']) protected click(event: MouseEvent): void {
        if (this.disabled && (event.target as HTMLElement)?.tagName === 'A') {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }
}
