<ng-container *ngIf="!inline; else inlineTemplate">
  <eb-form-item
    [label]="label"
    [prefixIcon]="icon"
    [size]="size"
    [theme]="theme"
    [required]="required"
    [suffixIcon]="showSuffixIcon ? suffixIcon : undefined"
    [isClearIcon]="!!searchFormControl.value && showSuffixIcon"
    (clearInput)="clear()"
    [isDisabled]="isDisabled"
  >
    <div *ngIf="!hintMode && value; else searchTmpl" class="eb-control-value">{{ value.label }}</div>
  </eb-form-item>

  <ng-template #searchTmpl>
    <input
      *ngIf="!isDisabled"
      #inputElement
      type="text"
      class="eb-input eb-test"
      [readonly]="!serverFilter"
      [placeholder]="placeholder | transloco"
      [formControl]="searchFormControl"
    />
  </ng-template>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="origin"
    [cdkConnectedOverlayWidth]="overlayWidth"
    [cdkConnectedOverlayOpen]="isOpen"
    (detach)="close()"
    (overlayOutsideClick)="onOverlayOutsideClick($event)"
  >
    <div class="eb-overlay-panel hm-60 p-0">
      <ng-container [ngTemplateOutlet]="inlineTemplate" />
    </div>
  </ng-template>
</ng-container>

<ng-template #inlineTemplate>
  <ng-content />
</ng-template>
