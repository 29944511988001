import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule } from '@angular/forms';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { UiButtonsModule } from './../../../ui-buttons/src/lib/ui-buttons.module';
import { UiFormItemModule } from './../../../ui-form-item/src/lib/ui-form-item.module';
import { EbDateRangePickerComponent } from './date-range-picker/date-range-picker.component';

import { EbDatePickerComponent } from './date-picker/date-picker.component';
import { EbDateRangeFormatPipe } from './date-range-format.pipe';
import { EbDateRangePanelComponent } from './date-range-panel/date-range-panel.component';
import { ScrollableDirective } from './scrollable.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        OverlayModule,
        NzDatePickerModule,
        UiButtonsModule,
        UiFormItemModule,
        TranslocoModule,
        SvgIconComponent,
    ],
    declarations: [
        EbDateRangePickerComponent,
        EbDateRangePanelComponent,
        EbDateRangeFormatPipe,
        EbDatePickerComponent,
        ScrollableDirective,
    ],
    exports: [
        EbDateRangePickerComponent,
        EbDateRangePanelComponent,
        EbDateRangeFormatPipe,
        EbDatePickerComponent,
        ScrollableDirective,
    ],
})
export class UiDatePickerModule {}
