import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { UiFormItemModule } from './../../../ui-form-item/src/lib/ui-form-item.module';
import { EbSelectOptionComponent } from './select-option/select-option.component';
import { EbSelectComponent } from './select/select.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, OverlayModule, UiFormItemModule, TranslocoModule],
    declarations: [EbSelectComponent, EbSelectOptionComponent],
    exports: [EbSelectComponent, EbSelectOptionComponent],
})
export class UiSelectModule {}
