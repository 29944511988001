import { DOCUMENT } from '@angular/common';
import { Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class BackgroundService {
    private readonly _document = inject(DOCUMENT);
    private readonly _renderer: Renderer2 = inject(RendererFactory2).createRenderer(null, null);

    public color(): void {
        this.clear();
        this._renderer.addClass(this._document.body, 'eb-bg-clr');
    }

    public dark(): void {
        this.clear();
        this._renderer.addClass(this._document.body, 'eb-bg-dark');
    }

    public img(): void {
        this.clear();
        this._renderer.addClass(this._document.body, 'eb-bg-img');
        this._renderer.addClass(this._document.body, 'eb-bg-img-home');
    }

    public imgSuccess(): void {
        this.clear();
        this._renderer.addClass(this._document.body, 'eb-bg-img-sucess');
        this._renderer.addClass(this._document.body, 'eb-bg-img--dark');
    }

    public imgError(): void {
        this.clear();
        this._renderer.addClass(this._document.body, 'eb-bg-img-error');
        this._renderer.addClass(this._document.body, 'eb-bg-img--dark');
    }

    public imgPending(): void {
        this.clear();
        this._renderer.addClass(this._document.body, 'eb-bg-img-bank-transfer');
        this._renderer.addClass(this._document.body, 'eb-bg-img--dark');
    }

    public imgBankTransfer(): void {
        this.clear();
        this._renderer.addClass(this._document.body, 'eb-bg-img-bank-transfer');
        this._renderer.addClass(this._document.body, 'eb-bg-img--dark');
    }

    public imgCategory(categoryName: string): void {
        this.clear();
        switch (categoryName) {
            case 'Koncerty':
                this._renderer.addClass(this._document.body, 'eb-bg-img');
                this._renderer.addClass(this._document.body, 'eb-bg-img-concerts');
                break;

            case 'Sport':
                this._renderer.addClass(this._document.body, 'eb-bg-img');
                this._renderer.addClass(this._document.body, 'eb-bg-img-sport');
                break;

            case 'Teatr':
                this._renderer.addClass(this._document.body, 'eb-bg-img');
                this._renderer.addClass(this._document.body, 'eb-bg-img-theatre');
                break;

            case 'Klasyka':
                this._renderer.addClass(this._document.body, 'eb-bg-img');
                this._renderer.addClass(this._document.body, 'eb-bg-img-classic');
                break;

            case 'Kino':
                this._renderer.addClass(this._document.body, 'eb-bg-img');
                this._renderer.addClass(this._document.body, 'eb-bg-img-cinema');
                break;

            case 'Widowiska':
                this._renderer.addClass(this._document.body, 'eb-bg-img');
                this._renderer.addClass(this._document.body, 'eb-bg-img-performances');
                break;

            case 'Dla dzieci':
                this._renderer.addClass(this._document.body, 'eb-bg-img');
                this._renderer.addClass(this._document.body, 'eb-bg-img-for-kids');
                break;

            case 'Targi | Wystawy':
                this._renderer.addClass(this._document.body, 'eb-bg-img');
                this._renderer.addClass(this._document.body, 'eb-bg-img-traid-faris-and-exhibitions');
                break;

            default:
                this._renderer.addClass(this._document.body, 'eb-bg-clr');
                break;
        }
    }

    public clear(): void {
        this._renderer.removeClass(this._document.body, 'eb-bg-clr');
        this._renderer.removeClass(this._document.body, 'eb-bg-dark');
        this._renderer.removeClass(this._document.body, 'eb-bg-img');
        this._renderer.removeClass(this._document.body, 'eb-bg-img-home');
        this._renderer.removeClass(this._document.body, 'eb-bg-img-sucess');
        this._renderer.removeClass(this._document.body, 'eb-bg-img-bank-transfer');
        this._renderer.removeClass(this._document.body, 'eb-bg-img--dark');
        this._renderer.removeClass(this._document.body, 'eb-bg-img-concerts');
        this._renderer.removeClass(this._document.body, 'eb-bg-img-sport');
        this._renderer.removeClass(this._document.body, 'eb-bg-img-theatre');
        this._renderer.removeClass(this._document.body, 'eb-bg-img-classic');
        this._renderer.removeClass(this._document.body, 'eb-bg-img-cinema');
        this._renderer.removeClass(this._document.body, 'eb-bg-img-performances');
        this._renderer.removeClass(this._document.body, 'eb-bg-img-for-kids');
        this._renderer.removeClass(this._document.body, 'eb-bg-img-traid-faris-and-exhibitions');
    }
}
