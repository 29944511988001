<div class="eb-date-range-picker__option-list scroll-hide" ebScrollable #list="ebScrollable" [scrollUnit]="scrollShift">
  <svg-icon class="eb-date-range-picker__left-icon" key="chevron-left" (click)="list.scroll(leftDirection)" />
  <button eb-button ebType="secondary" ebSize="small" [ebGhost]="true" (click)="selectStiff(DateParams.TODAY)">
    {{ 'libs.date-range-panel.today' | transloco }}
  </button>
  <button eb-button ebType="secondary" ebSize="small" [ebGhost]="true" (click)="selectStiff(DateParams.TOMORROW)">
    {{ 'libs.date-range-panel.tomorrow' | transloco }}
  </button>
  <button eb-button ebType="secondary" ebSize="small" [ebGhost]="true" (click)="selectStiff(DateParams.CURRENT_WEEK)">
    {{ 'libs.date-range-panel.thisWeek' | transloco }}
  </button>
  <button eb-button ebType="secondary" ebSize="small" [ebGhost]="true" (click)="selectStiff(DateParams.NEXT_WEEK)">
    {{ 'libs.date-range-panel.nextWeek' | transloco }}
  </button>
  <button eb-button ebType="secondary" ebSize="small" [ebGhost]="true" (click)="selectStiff(DateParams.NEAREST_WEEKEND)">
    {{ 'libs.date-range-panel.upcomingWeekend' | transloco }}
  </button>
  <button eb-button ebType="secondary" ebSize="small" [ebGhost]="true" (click)="selectStiff(DateParams.NEXT_WEEKEND)">
    {{ 'libs.date-range-panel.nextWeekend' | transloco }}
  </button>
  <button eb-button ebType="secondary" ebSize="small" [ebGhost]="true" (click)="selectStiff(DateParams.CURRENT_MONTH)">
    {{ 'libs.date-range-panel.currentMonth' | transloco }}
  </button>
  <button eb-button ebType="secondary" ebSize="small" [ebGhost]="true" (click)="selectStiff(DateParams.NEXT30DAYS)">
    {{ 'libs.date-range-panel.next30days' | transloco }}
  </button>
  <svg-icon class="eb-date-range-picker__right-icon" key="chevron-right" (click)="list.scroll(rightDirection)" />
</div>
<nz-range-picker
  *ngIf="isOpen"
  [ngModel]="value"
  (ngModelChange)="select($event)"
  (nzOnCalendarChange)="disableEarlierDates($event)"
  [nzDisabledDate]="disabledDate"
  nzInline
  nzDropdownClassName="eb-eb-date-range-picker__control"
/>
