import { Injectable } from '@angular/core';
import {
    addDays,
    endOfDay,
    endOfMonth,
    endOfTomorrow,
    endOfWeek,
    isFriday,
    isSameDay,
    isSaturday,
    isSunday,
    isToday,
    isTomorrow,
    lightFormat,
    nextFriday,
    nextMonday,
    nextSunday,
    startOfDay,
    startOfTomorrow,
} from 'date-fns';
import { pl } from 'date-fns/locale';
import { DateParams, DateRangeValue } from './src/lib/date-range-panel/date-range-panel.component';

@Injectable({ providedIn: 'root' })
export class DateRangeService {
    mapDateParamToDateRange(dateParam: DateParams) {
        const today = new Date();
        let _nextMonday;
        switch (dateParam) {
            case DateParams.TODAY:
                return [startOfDay(today), endOfDay(today)];
            case DateParams.TOMORROW:
                return [startOfTomorrow(), endOfTomorrow()];
            case DateParams.CURRENT_WEEK:
                return [startOfDay(today), endOfWeek(today, { locale: pl })];
            case DateParams.NEXT_WEEK:
                _nextMonday = nextMonday(today);
                return [startOfDay(_nextMonday), endOfDay(nextSunday(_nextMonday))];
            case DateParams.NEAREST_WEEKEND:
                return this._getNearestWeekend(today);
            case DateParams.NEXT_WEEKEND:
                _nextMonday = nextMonday(today);
                return [startOfDay(nextFriday(_nextMonday)), endOfDay(nextSunday(_nextMonday))];
            case DateParams.CURRENT_MONTH:
                return [startOfDay(today), endOfMonth(today)];
            case DateParams.NEXT30DAYS:
                return [startOfDay(today), endOfDay(addDays(today, 30))];
        }
    }

    _lightFormat(date: Date) {
        // eslint-disable-next-line @typescript-eslint/quotes
        return lightFormat(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    }

    mapDateParamToLightFormatDateRange(param: DateParams) {
        const dateRange = this.mapDateParamToDateRange(param);
        return [this._lightFormat(dateRange[0]), this._lightFormat(dateRange[1])];
    }

    isDateRangeStiff(dateRange: DateRangeValue | null): DateParams | null {
        if (!dateRange) {
            return null;
        }
        const today = new Date();
        const dateRangeFrom = dateRange[0];
        const dateRangeTo = dateRange[1];

        if (isToday(dateRangeFrom)) {
            return this._checkRangeStartingWithToday(dateRangeTo, today);
        } else if (isTomorrow(dateRangeFrom) && isTomorrow(dateRangeTo)) {
            return DateParams.TOMORROW;
        } else if (
            isSameDay(dateRangeFrom, nextMonday(today)) &&
            isSameDay(dateRangeTo, nextSunday(nextMonday(today)))
        ) {
            return DateParams.NEXT_WEEK;
        } else if (this._isNearestWeekend(dateRange, today)) {
            return DateParams.NEAREST_WEEKEND;
        } else if (
            isSameDay(dateRangeFrom, nextFriday(nextMonday(today))) &&
            isSameDay(dateRangeTo, nextSunday(nextMonday(today)))
        ) {
            return DateParams.NEXT_WEEKEND;
        }
        return null;
    }

    private _getNearestWeekend(today: Date) {
        if (isFriday(today) || isSaturday(today)) {
            return [startOfDay(today), endOfDay(nextSunday(today))];
        } else if (isSunday(today)) {
            return [startOfDay(today), endOfDay(today)];
        } else {
            return [startOfDay(nextFriday(today)), endOfDay(nextSunday(today))];
        }
    }

    private _checkRangeStartingWithToday(dateRangeTo: Date, today: Date): DateParams | null {
        if (isToday(dateRangeTo)) {
            return DateParams.TODAY;
        } else if (isSameDay(dateRangeTo, nextSunday(today))) {
            return DateParams.CURRENT_WEEK;
        } else if (isSameDay(dateRangeTo, endOfMonth(today))) {
            return DateParams.CURRENT_MONTH;
        } else if (isSameDay(dateRangeTo, addDays(today, 30))) {
            return DateParams.NEXT30DAYS;
        }
        return null;
    }

    private _isNearestWeekend(dateRange: DateRangeValue, today: Date): boolean {
        if (isFriday(today) || isSaturday(today)) {
            return isSameDay(dateRange[0], today) && isSameDay(dateRange[1], nextSunday(today));
        } else {
            return isSameDay(dateRange[0], nextFriday(today)) && isSameDay(dateRange[1], nextSunday(today));
        }
    }
}
