import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, Renderer2, RendererFactory2, inject } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GtmInitializerService {
    private _platformId = inject(PLATFORM_ID);

    static readonly inited$ = new ReplaySubject<boolean>(1);
    private readonly _renderer: Renderer2;

    constructor() {
        this._renderer = inject(RendererFactory2).createRenderer(null, null);
    }

    public init(): void {
        if (isPlatformBrowser(this._platformId)) {
            GtmInitializerService.inited$.next(true);
            GtmInitializerService.inited$.complete();
        }
    }

    public manualConsentUpdate() {
        if (isPlatformBrowser(this._platformId)) {
            const theWindow = window as any;

            theWindow.gtag("consent", "update", {
              ad_personalization: "granted",
              ad_storage: "granted",
              ad_user_data: "granted",
              analytics_storage: "granted",
              functionality_storage: "granted",
              personalization_storage: "granted",
              security_storage: "granted",
              wait_for_update: 500
            });
        }
    }

    /* eslint-disable */
    private _run(w: any, d: any, s: any, l: any, i: any): void {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        });
        w[l].push(['config', i]);
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    }
}
