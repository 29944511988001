import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SyneriseService } from './synerise.service';

@NgModule({
    imports: [CommonModule],
})
export class SyneriseModule {
    static forRoot(): ModuleWithProviders<SyneriseModule> {
        return {
            ngModule: SyneriseModule,
            providers: [SyneriseService],
        };
    }
}
