import { Directive, ElementRef, Input, inject } from '@angular/core';

@Directive({
    selector: '[ebScrollable]',
    exportAs: 'ebScrollable',
})
export class ScrollableDirective {
    private readonly _elementRef = inject(ElementRef);

    @Input() scrollUnit!: number;

    private get _element(): any {
        return this._elementRef.nativeElement;
    }

    // -1 - direction left , 1 - direction right
    public scroll(direction: number): void {
        this._element.scrollLeft += this.scrollUnit * direction;
    }
}
