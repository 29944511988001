export interface IFrame {
    domain: string;
    type: IFrameTypeEnum;
    primaryColor?: string;
    activeBtnTxtColor?: string;
    footerVisible?: boolean;
}

export enum IFrameTypeEnum {
    TRANSPARENT = '0',
    LIGHT = '1',
    DARK = '2',
}
