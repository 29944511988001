import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DestroyRef, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ITitleOnTime } from 'apps/portal/src/app/rest-api/models/category.model';
import { EventSearch, TitleSearch } from 'apps/portal/src/app/rest-api/models/search-response.model';
import { TitlePage } from 'apps/portal/src/app/rest-api/models/title-page.model';
import { nameToId } from 'apps/portal/src/consts/name-to-id';
import { Title } from '../../../../apps/portal/src/app/rest-api/models/title.model';
import { GtmInitializerService } from './gtm-initializer.service';
import { INPMeasuringService } from './inp-measuring.service';
import { PageData } from './models/page-data.model';
import { IVirtualPageView } from './virtual-page-view';

@Injectable()
export class GtmService {
    private readonly _platformId = inject(PLATFORM_ID);
    private _inpMeasuringService = inject(INPMeasuringService);
    private _destroyRef = inject(DestroyRef);

    constructor() {
        if (isPlatformBrowser(this._platformId)) {
            this._inpMeasuringService.onINP(this._inpMeasuringService._sendToGoogleAnalytics);
        }
    }

    public pageView(): void {
        if (isPlatformServer(this._platformId)) {
            return;
        }
        GtmInitializerService.inited$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isInit) => {
            if (isInit) {
                const theWindow = window as any;
                const theDocument = document as any;
                const theLocation = location as any;

                theWindow.dataLayer.push({
                    event: 'eb_page_view',
                    page_title: theDocument.title,
                    page_location: theLocation.href,
                    page_path: theLocation.pathname,
                });
            }
        });
    }

    public pageModel(pageModelEventData: PageData): void {
        if (isPlatformServer(this._platformId)) {
            return;
        }

        GtmInitializerService.inited$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isInit) => {
            if (isInit) {
                const theWindow = window as any;

                theWindow.dataLayer.push({
                    event: GtmVariableNames.PageModel,
                    ...pageModelEventData,
                });
            }
        });
    }

    public organizer(organizerId: string | null): void {
        if (isPlatformServer(this._platformId)) {
            return;
        }

        GtmInitializerService.inited$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isInit) => {
            if (isInit) {
                const theWindow = window as any;

                theWindow.dataLayer.push({
                    event: GtmVariableNames.Organizer,
                    'organizer-id': organizerId,
                });
            }
        });
    }

    public deleteEvents(eventsToDelete: GtmVariableNames[]): void {
        if (isPlatformServer(this._platformId)) {
            return;
        }

        GtmInitializerService.inited$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isInit) => {
            if (isInit) {
                const theWindow = window as any;
                theWindow.dataLayer.forEach((event: any, index: number) => {
                    if (eventsToDelete.includes(event.event)) {
                        theWindow.dataLayer.splice(index, 1);
                    }
                });
            }
        });
    }

    public setVariable(variableName: GtmVariableNames | GtmVariableNames[], value: unknown): void {
        if (isPlatformServer(this._platformId)) {
            return;
        }
        GtmInitializerService.inited$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isInit) => {
            if (isInit) {
                const theWindow = window as any;
                if (Array.isArray(variableName)) {
                    theWindow.gtag(...variableName, value);
                } else {
                    theWindow.dataLayer.push({ [variableName]: value });
                }
            }
        });
    }

    public setVariableObject(dataLayerObject: any): void {
        if (isPlatformServer(this._platformId)) {
            return;
        }
        GtmInitializerService.inited$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isInit) => {
            if (isInit) {
                const theWindow = window as any;
                theWindow.dataLayer.push(dataLayerObject);
            }
        });
    }

    public setVirtualPageView(virtualPageView: IVirtualPageView): void {
        if (isPlatformServer(this._platformId)) {
            return;
        }
        GtmInitializerService.inited$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isInit) => {
            if (isInit) {
                const theWindow = window as any;
                theWindow.dataLayer.push(virtualPageView);
            }
        });
    }

    public viewItem(titlePage: TitlePage, itemListName: string = '', itemListId: string = ''): void {
        const item = this.mapTitlePageToGa4Item(titlePage, itemListName, itemListId);
        this.setGa4Event([item], GtmVariableNames.ViewItem);
    }

    public selectItem(
        title: Title,
        itemListName?: string,
        itemListId?: string,
        index?: number,
        promotionName?: string,
        promotionId?: string,
    ): void {
        const item = this.mapTitleToGa4Item(title, itemListName, itemListId, index, promotionName, promotionId);
        this.setGa4Event([item], GtmVariableNames.SelectItem, itemListName, itemListId);
    }

    public selectPromotion(
        title: Title,
        itemListName?: string,
        itemListId?: string,
        promotionName?: string,
        promotionId?: string,
        index?: number,
    ): void {
        const item = this.mapTitleToGa4Item(title, itemListName, itemListId, index, promotionName, promotionId);
        this.setGa4Event(
            [item],
            GtmVariableNames.SelectPromotion,
            undefined,
            undefined,
            undefined,
            promotionName,
            promotionId,
        );
    }

    public addToWishlist(item: Ga4Item): void {
        this.setGa4Event([item], GtmVariableNames.AddToWishlist);
    }

    public viewItemList(
        titles: Title[],
        itemListName?: string,
        itemListId?: string,
        promotionName?: string,
        promotionId?: string,
    ): void {
        const items = titles.map((title) =>
            this.mapTitleToGa4Item(title, itemListName, itemListId, title.index, promotionName, promotionId),
        );
        this.setGa4Event(items, GtmVariableNames.ViewItemList, itemListName, itemListId);
    }

    public setGa4Event(
        items: Ga4Item[],
        event: GtmVariableNames,
        itemListName?: string,
        itemListId?: string,
        currency?: string,
        promotionName?: string,
        promotionId?: string,
    ): void {
        if (isPlatformServer(this._platformId)) {
            return;
        }

        let value: any;
        value = { items } as Ga4Event;

        if ([GtmVariableNames.AddToWishlist, GtmVariableNames.ViewItem].includes(event)) {
            value = value as Ga4PriceEvent;

            if (currency) {
                value.currency = currency;
            }
        } else if ([GtmVariableNames.SelectItem, GtmVariableNames.ViewItemList].includes(event)) {
            value = value as Ga4ListEvent;

            if (itemListName) {
                value.item_list_name = itemListName;
            }

            if (itemListId) {
                value.item_list_id = itemListId;
            }
        } else if ([GtmVariableNames.ViewPromotion, GtmVariableNames.SelectPromotion].includes(event)) {
            if (promotionName) {
                value.promotion_name = promotionName;
            }

            if (promotionId) {
                value.promotion_id = promotionId;
            }
        }

        GtmInitializerService.inited$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isInit) => {
            if (isInit) {
                const theWindow = window as any;
                theWindow.dataLayer.push({ ecommerce: null });
                theWindow.dataLayer.push({
                    event,
                    ecommerce: value,
                });
            }
        });
    }

    public getPromotionNameForGA4(
        promotionName: string,
        manualOrder?: boolean,
        promotedTitle?: boolean,
        promotedCarousel?: boolean,
    ): string {
        if (manualOrder || promotedCarousel) {
            return promotedTitle ? `${promotionName ? promotionName + ' - ' : ''}promocja` : promotionName;
        } else if (promotedTitle) {
            return 'promocja';
        } else {
            return '';
        }
    }

    public mapTitleToGa4Item(
        title: Title,
        itemListName?: string,
        itemListId?: string,
        index?: number,
        promotionName?: string,
        promotionId?: string,
    ): Ga4Item {
        const item = {
            item_id: title.id,
            item_name: title.title,
            item_category: title.category ?? title.categoryName,
            item_category2: title.subcategory ?? title.subcategoryName,
        } as Ga4Item;

        if (title.organizer?.name) {
            item.item_brand = title.organizer.name;
        }

        if (title.place?.split(', ').length === 1) {
            item.item_category3 = title.place;
            item.item_category4 = title.nextEventPlace?.customName;
        }

        if (index || index === 0) {
            item.index = index;
        }

        if (itemListName) {
            item.item_list_name = itemListName;
        }

        if (itemListId) {
            item.item_list_id = itemListId;
        }

        if (promotionName) {
            item.promotion_name = promotionName;
        }

        if (promotionId) {
            item.promotion_id = promotionId;
        }

        return this._addItemListInfo(item, itemListName, itemListId, index);
    }

    public mapTitlePageToGa4Item(titlePage: TitlePage, itemListName: string = '', itemListId: string = ''): Ga4Item {
        const item = {
            item_id: titlePage.id,
            item_name: titlePage.title,
            item_brand: titlePage.organizer?.name,
            item_category: titlePage.category.name,
            item_category2: titlePage.category.subcategory?.name,
        } as Ga4Item;

        if (titlePage.places?.length === 1) {
            item.item_category3 = titlePage.places[0].city;
            item.item_category4 = titlePage.places[0].name;
        }

        if (itemListName && itemListId) {
            item.item_list_name = itemListName;
            item.item_list_id = itemListId;
            item.promotion_name = itemListName;
            item.promotion_id = itemListId;
        }

        return item;
    }

    public mapTitleSearchToGa4Item(
        titleSearch: TitleSearch,
        itemListName?: string,
        itemListId?: string,
        index?: number,
        promotionName?: string,
        promotionId?: string,
    ): Ga4Item {
        const item = {
            item_id: titleSearch.id.toString(),
            item_name: titleSearch.title,
            // TODO: item_brand
            item_category: titleSearch.categoryName,
            item_category2: titleSearch.subcategoryName,
            index,
            promotion_id: promotionId,
            promotion_name: promotionName,
        } as Ga4Item;

        return this._addItemListInfo(item, itemListName, itemListId, index);
    }

    public mapEventSearchToGa4Item(
        eventSearch: EventSearch,
        itemListName?: string,
        itemListId?: string,
        index?: number,
        promotionName?: string,
        promotionId?: string,
    ): Ga4Item {
        const item = {
            item_id: eventSearch.id,
            item_name: eventSearch.title,
            // TODO: item_brand
            item_category: eventSearch.category,
            item_category2: eventSearch.subategory,
            index,
            promotion_id: promotionId,
            promotion_name: promotionName,
        } as Ga4Item;

        return this._addItemListInfo(item, itemListName, itemListId, index);
    }

    public mapItitleOnTimeToGa4Item(iTitleOnTime: ITitleOnTime, itemListName: string, itemListId: string): Ga4Item {
        const item = {
            item_id: iTitleOnTime.id,
            item_name: iTitleOnTime.title,
            item_category: iTitleOnTime.category,
            item_category2: iTitleOnTime.subcategory,
            promotion_name: iTitleOnTime.promotionName,
            promotion_id: nameToId(iTitleOnTime.promotionName),
        } as Ga4Item;

        return this._addItemListInfo(item, itemListName, itemListId, iTitleOnTime.index);
    }

    private _addItemListInfo(item: Ga4Item, itemListName?: string, itemListId?: string, index?: number): Ga4Item {
        if (index || index === 0) {
            item.index = index;
        }

        if (itemListName) {
            item.item_list_name = itemListName;
        }

        if (itemListId) {
            item.item_list_id = itemListId;
        }

        return item;
    }
}

export interface Ga4Item {
    item_id: string;
    item_name: string;
    item_brand?: string;
    item_category: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    price?: string;
    currency?: string;
    item_list_name?: string;
    item_list_id?: string;
    promotion_name?: string;
    promotion_id?: string;
    index?: number;
}

export interface Ga4Event {
    items: Ga4Item[];
}

export interface Ga4ListEvent extends Ga4Event {
    item_list_name?: string;
    item_list_id?: string;
}

export interface Ga4PriceEvent extends Ga4Event {
    currency?: string;
    value?: string;
}

export enum GtmVariableNames {
    Organizer = 'organizer',
    PageModel = 'page-model',
    VirtualPageView = 'virtualpageview',
    ViewItem = 'view_item',
    SelectItem = 'select_item',
    ViewPromotion = 'view_promotion',
    SelectPromotion = 'select_promotion',
    AddToWishlist = 'add_to_wishlist',
    ViewItemList = 'view_item_list',
}

export enum Ga4ListNames {
    Featured = 'Karuzela główna',
    Additional = 'Karuzela własna ',
    ForYou = 'Karuzela Wybrane dla ciebie',
    OnTime = 'Karuzela Na czasie',
    LastMinute = 'Karuzela Ostatnia chwila na zakupy',
    VisitedTitles = 'Karuzela Ostatnio oglądane',
    Deals = 'Okazje eBilet',
    Events = 'Wydarzenia - Nowości',
    EventsOnTime = 'Wydarzenia - Na czasie',
    ArtistCurrentEvents = 'Artysta - Wydarzenia aktualne',
    ArtistSuggestedTitles = 'Artysta - Wydarzenia wybrane dla ciebie',
    ArtistHistoricalTitles = 'Artysta - Wydarzenia zakończone',
    ArtistPopularTitles = 'Artysta - Mogą Ci się spodobać',
    OrganizerCurrentTitles = 'Organizator - Wydarzenia aktualne',
    OrganizerHistoricalTitles = 'Organizator - Wydarzenia zakończone',
    PlaceCurrentEvents = 'Miejsce - Wydarzenia aktualne',
    PlaceSuggestedTitles = 'Miejsce - Wydarzenia wybrane dla ciebie',
    PlaceHistoricalTitles = 'Miejsce - Wydarzenia zakończone',
    PlacePopularTitles = 'Miejsce - Mogą Ci się spodobać',
    Search = 'Wyniki wyszukiwania',
    SearchSuggest = 'Search Suggest',
    Subcategory = 'Strona podkategorii',
    ProposedTitles = 'Podobne wydarzenia',
    OrganizerTitles = 'Inne wydarzenia organizatora',
    CategoryCarousel = 'Karuzela Kategoria ',
    LandingPageLocation = 'Lokalizacja ',
    LandingPage = 'LandingPage ',
    DontMiss = 'Nie przegap',
    MegamenuOnTime = 'Megamenu - Na czasie',
    MegamenuCategory = 'Megamenu - Kategoria - Na czasie',
}
