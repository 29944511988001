import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EbButtonComponent } from './button/button.component';

@NgModule({
    imports: [CommonModule],
    declarations: [EbButtonComponent],
    exports: [EbButtonComponent],
})
export class UiButtonsModule {}
