import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { FormItemComponent } from './form-item/form-item.component';

@NgModule({
    imports: [CommonModule, SvgIconComponent],
    declarations: [FormItemComponent],
    exports: [FormItemComponent],
})
export class UiFormItemModule {}
