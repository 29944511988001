import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, inject } from '@angular/core';
import { SvgIcons } from '@ngneat/svg-icon';

export type EbInputTheme = 'dark' | 'light';
export type EbInputSize = 'large' | 'default' | 'small';

@Component({
    selector: 'eb-form-item',
    templateUrl: './form-item.component.html',
    styleUrls: ['./form-item.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'block' },
})
export class FormItemComponent {
    public readonly elementRef = inject(ElementRef);

    @Input() onlyIcon = false;
    @Input() prefixIcon?: SvgIcons;
    @Input() suffixIcon?: SvgIcons;
    @Input() label!: string;
    @Input() size: EbInputSize = 'default';
    @Input() theme: EbInputTheme = 'dark';
    @Input() radio = false;
    @Input() required = false;
    @Input() isClearIcon = false;
    @Input() isDisabled = false;

    @Output() readonly clearInput = new EventEmitter<void>();

    protected onClearInput(event: Event): void {
        event.stopPropagation();
        if (this.clearInput.observed) {
            this.clearInput.emit();
        }
    }
}
