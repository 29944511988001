import { Pipe, PipeTransform } from '@angular/core';
import { lightFormat } from 'date-fns';
import { DateRangeValue } from './date-param-with-range.model';

@Pipe({ name: 'ebDateRangeFormat' })
export class EbDateRangeFormatPipe implements PipeTransform {
    transform(dateRange: DateRangeValue): string {
        if (dateRange) {
            try {
                const dateFormat = 'dd.MM.yyyy';
                const start = lightFormat(dateRange[0], dateFormat);
                const end = lightFormat(dateRange[1], dateFormat);
                return start === end ? start : `${start} - ${end}`;
            } catch {
                return '';
            }
        }
        return '';
    }
}
