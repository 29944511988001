import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CookiesPolicyService } from '../../../cookies-policy/src/lib/cookies-policy.service';
import { ISyneriseClient } from './model/synerise-client.interface';
import { SyneriseInitializerService } from './synerise-initializer.service';

@Injectable()
export class SyneriseService {
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _cookiesPolicyService = inject(CookiesPolicyService);

    public pageView(): void {
        SyneriseInitializerService.inited$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(
          (isInit: boolean) => {
            if(isInit) {
              const theWindow = window as any;
              theWindow.SR?.event.pageVisit().then(() => {
                  theWindow.SR?.dynamicContent.get();
              });
            }
          }
        );
    }

    public getClient(): Observable<ISyneriseClient | null> {
        return SyneriseInitializerService.inited$.pipe(
          switchMap((isInit) => {
            const theWindow = window as any;
            if (theWindow.SR) {
                return from(theWindow.SR.client?.getCurrentClient()) as Observable<ISyneriseClient>;
            } else {
                return of(null);
            }
          })
        );
    }

    public sendData<Payload>(payload: Payload, key: string): void {
        SyneriseInitializerService.inited$.pipe(takeUntilDestroyed(this._destroyRef))
          .subscribe((isInit: boolean) => {
            if(isInit) {
              const theWindow = window as any;
              theWindow.SR?.event.sendFormData(key, payload);
            }
        });
    }
}
