import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Injectable, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, ReplaySubject } from 'rxjs';
import { REQUEST } from '../../../../apps/portal/src/consts/request-response.const';
import { EB_BREAKPOINT } from './eb-breakpoint.const';

@Injectable({
    providedIn: 'root',
})
export class DeviceService implements OnDestroy {
    private readonly _breakpointObserver = inject(BreakpointObserver);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _request = inject(REQUEST, { optional: true });

    private readonly _isMobile = new ReplaySubject<boolean>(1);
    private readonly _mobileRegex =
        // eslint-disable-next-line max-len
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i;

    private readonly _iosRegex = /(iphone|ipad|ipod)/i;
    private readonly _safariRegex = /^((?!chrome|android).)*safari/i;

    public get isMobile$(): Observable<boolean> {
        return this._isMobile.asObservable();
    }

    constructor() {
        if (isPlatformBrowser(this._platformId)) {
            this._breakpointObserver
                .observe([EB_BREAKPOINT.ScreenLarge, EB_BREAKPOINT.ScreenXL, EB_BREAKPOINT.ScreenXXL])
                .pipe(takeUntilDestroyed())
                .subscribe((result) => {
                    this._isMobile.next(!result.matches);
                });
        } else {
            this._isMobile.next(this._request?.headers ? this._request?.headers['x-is-mobile-device'] === '1' : false);
        }
    }

    private _checkIfUserAgentIsMobile(userAgent?: string): boolean {
        return userAgent ? this._mobileRegex.test(userAgent as string) : false;
    }

    public checkIfUserAgentIsIosOrSafari(): boolean {
        const userAgent = isPlatformBrowser(this._platformId)
            ? navigator?.userAgent
            : this._request?.headers['user-agent'];

        if (userAgent) {
            return this._iosRegex.test(userAgent) || this._safariRegex.test(userAgent);
        }

        return false;
    }

    public observe(breakpoints: string[]): Observable<BreakpointState> {
        return this._breakpointObserver.observe(breakpoints);
    }

    public ngOnDestroy(): void {
        this._isMobile.complete();
    }
}
