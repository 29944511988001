import { DOCUMENT } from '@angular/common';
import { Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ColorHelper } from '../helpers/color.helper';
import { IBranding } from '../rest-api/models/title-page.model';

@Injectable({
    providedIn: 'root',
})
export class BrandingService {
    private readonly _document = inject(DOCUMENT);
    private _renderer: Renderer2 = inject(RendererFactory2).createRenderer(this._document.body, null);

    private readonly _brandingSubject = new ReplaySubject<IBranding | null>(1);
    private _branding: IBranding | null = null;

    public get branding$(): Observable<IBranding | null> {
        return this._brandingSubject.asObservable();
    }

    public setBranding(branding?: IBranding | null): void {
        this._branding = this._convertBrandingColors(branding ?? null);
        this.setButtonBackgroundColor(branding?.buyTicketColor);
        this.setButtonFontColor(branding?.buyTicketFontColor);

        this._shareBranding();
    }

    private _shareBranding(): void {
        this._brandingSubject.next(this._branding);
    }

    public setButtonBackgroundColor(color: string | undefined): void {
        if (color) {
            this._renderer.setStyle(this._document.body, '--eb-btn-primary-bg', color, 2);
            this._renderer.setStyle(this._document.body, '--eb-btn-primary-bg-hover', ColorHelper.darken(color, 40), 2);
        } else {
            this._renderer.removeStyle(this._document.body, '--eb-btn-primary-bg', 2);
            this._renderer.removeStyle(this._document.body, '--eb-btn-primary-bg-hover', 2);
        }
    }

    public setButtonFontColor(color: string | undefined): void {
        if (color) {
            this._renderer.setStyle(this._document.body, '--eb-btn-primary-color', color, 2);
        } else {
            this._renderer.removeStyle(this._document.body, '--eb-btn-primary-color', 2);
        }
    }

    private _convertBrandingColors(branding: IBranding | null): IBranding | null {
        if (branding) {
            branding.headerColor = ColorHelper.hashColor(branding.headerColor);
            branding.footerColor = ColorHelper.hashColor(branding.footerColor);
            branding.buyTicketColor = ColorHelper.hashColor(branding.buyTicketColor);
            branding.buyTicketFontColor = ColorHelper.hashColor(branding.buyTicketFontColor);
        }

        return branding;
    }
}
