import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, Renderer2, RendererFactory2, inject } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { IframeService } from '../../../../apps/portal/src/app/services/iframe.service';
import { IFrame } from '../../../../apps/portal/src/app/rest-api/models/iframe';

@Injectable({
    providedIn: 'root',
})
export class SyneriseInitializerService {
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _document = inject(DOCUMENT);
    private readonly _renderer: Renderer2;
    private _iframeService = inject(IframeService);

    static readonly inited$ = new ReplaySubject<boolean>(1);

    constructor() {
        this._renderer = inject(RendererFactory2).createRenderer(null, null);
    }

    public init(trackerKey: string): void {
        if (isPlatformBrowser(this._platformId)) {
            this._iframeService.iframe$.subscribe((iframe: IFrame|undefined) => {
                if (iframe) {
                    this.addSyneriseScript(trackerKey, false);
                }
            });
        }
    }

    public inited() {
        SyneriseInitializerService.inited$.next(true);
        SyneriseInitializerService.inited$.complete()
    }

    public addSyneriseScript(trackerKey: string, plain: boolean) {
        const script = this._renderer.createElement('script');

        if (plain) {
            script.setAttribute('data-cookieconsent', 'marketing');
            script.type = 'text/plain';
        } else {
            script.type = 'text/javascript';
        }

        script.text = `
                  function onSyneriseLoad() {
                    SR.init({
                      'trackerKey': '${trackerKey}',
                      'dataLayer': dataLayer,
                      'trackingDomain': 'https://syn.ebilet.pl',
                      'customPageVisit': true,
                      'dynamicContent': {
                        'virtualPage': true
                      }
                    });
                    var syneriseEvent = new CustomEvent('ebSyneriseInited', {detail: {message: 'synerise inited'}});
                    dispatchEvent(syneriseEvent);
                  }

                  (function(s,y,n,e,r,i,se){s['SyneriseObjectNamespace']=r;s[r]=s[r]||[],
                    s[r]._t=1*new Date(),s[r]._i=0,s[r]._l=i;var z=y.createElement(n),
                    se=y.getElementsByTagName(n)[0];z.async=1;z.src=e;se.parentNode.insertBefore(z,se);
                    z.onload=z.onreadystatechange=function(){var rdy=z.readyState;
                      if(!rdy||/complete|loaded/.test(z.readyState)){s[i]();z.onload = null;
                        z.onreadystatechange=null;}};})(window,document,'script',
                    'https://syn.ebilet.pl/jgfueyl20.js','SR', 'onSyneriseLoad');
              `;

        this._renderer.appendChild(this._document.head, script);
    }
}
